import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment/moment';
import styled from 'styled-components';
import media from 'styled-media-query';
import { themeGet } from '@styled-system/theme-get';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import {
  Col,
  Title,
  Row,
  Text,
  Link,
  BodyTitle,
  Box,
} from '@firsttable/web-components/atoms';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import BookingFooter from '@firsttable/web-components/organisms/Footer/BookingFooter';
import gql from 'graphql-tag';
import { navigate } from '@reach/router';
import { updateFacebookOfferMutation } from '@firsttable/graphql-queries/src';
import {
  freeCreditAmount,
  errorMessage,purchaseEvent,
} from '@firsttable/functions';
import PromotionModal from '@firsttable/web-components/organisms/PromotionModal';
import { useAlert } from 'react-alert';
import ThemeLayout from './layout';
import MainMenu from '../components/organisms/Navigation/Menu';
import SEO from '../components/seo';
import { fbq } from '../helpers/events';
import LoadingLayout from './LoadingLayout';
import ModalContext from '../context/ModalContext';
import ErrorLayout from './ErrorLayout';
import Logger from '../helpers/logger';
import NavLink from '../components/atoms/NavLink';
import useFacebookLoading from '../hooks/loadFacebook';

const logger = new Logger('Booking Confirmation');
const SET_GA_TRACKING = gql`
  mutation updateBooking($reference: String!) {
    updateBookingPostConfirmation(
      reference: $reference
      sentToGoogleAnalytics: true
    ) {
      sentToGoogleAnalytics
    }
  }
`;

const StyledInfoBox = styled(Box)`
  padding: 58px 64px;
  ${media.lessThan(bp.s)`
    border-top: 1px ${themeGet('colors.grayscale.300')} solid;
    border-bottom: 1px ${themeGet('colors.grayscale.300')} solid;
    margin: 20px 0;
    padding: 20px 0;
    background: none;
    padding: 30px 20px;
  `};
`;

const BookingConfirmationContent = ({ booking }) => {
  const [updateBookingPostConfirmation] = useMutation(SET_GA_TRACKING);
  useEffect(() => {
    const { conversionData } = booking;
    const convData = JSON.parse(conversionData);
    logger.debug('Booking data', convData);
    logger.debug('Booking data', booking);
    if (booking.id && !booking.sentToGoogleAnalytics) {
      purchaseEvent(booking, convData, process.env.GATSBY_SITE_ID);
    }
    fbq('track', 'Purchase', {
      value: convData.Paid,
      currency: booking.currency,
      content_name: booking.restaurant.menuTitle,
      content_type: 'product',
      content_ids: [booking.restaurant.id],
      num_item: 1,
    });
    // mark sent to google analytics
    updateBookingPostConfirmation({
      variables: { reference: booking.reference },
    }).catch((e) => e);
  }, []);

  return (
    <ContainerContent>
      <RowHead title="Reservation Complete" />
      <Row>
        <Col width={[1, 1 / 2]} order={[1, 0]}>
          <BodyTitle>Congratulations!</BodyTitle>
          <Text>
            Your reservation confirmation will arrive in your inbox shortly.{' '}
            Make sure you print the reservation out and bring it with you, or
            have it on your phone to show the restaurant.
          </Text>
          <Text>
            Your reservation confirmation will be emailed to{' '}
            <Box as="span" color="brand.red">
              {booking.email}
            </Box>
          </Text>
          <Text>
            <Link
              href={`${process.env.GATSBY_SERVER_ENDPOINT}BookingController/${booking.reference}/downloadReservationConfirmation`}
              color="brand.red"
              underline
              download
            >
              Download Reservation Confirmation
            </Link>
          </Text>
        </Col>
        <Col width={[1, 1 / 2]}>
          <StyledInfoBox colors="gradient.blue">
            <Title as="h6" size="h6" m="0" color="gold.800">
              Discount
            </Title>
            <Title as="h3">50% off food</Title>
            <Box>
              <Title as="h6" size="h6">
                {moment(booking.time, 'HH:mm:ss').format('h:mm A')},{' '}
                {moment(booking.date).format('dddd Do MMMM')}
              </Title>
              <Text>{booking.restaurant.title}</Text>
            </Box>
          </StyledInfoBox>
        </Col>
      </Row>
    </ContainerContent>
  );
};

BookingConfirmationContent.propTypes = {
  booking: PropTypes.shape().isRequired,
};

const ModalToDisplay = ({ shareData, siteConfig }) => {
  const { hideModalFunc } = useContext(ModalContext);
  const { BookingReference, RestaurantLink, Qualified } = shareData;
  const alert = useAlert();
  const [updateFacebookOffer] = useMutation(updateFacebookOfferMutation);
  useFacebookLoading();
  if (!Qualified) {
    return null;
  }
  if (!shareData) {
    return (
      <Box as="span" display="hidden">
        Modal loading
      </Box>
    );
  }

  const fbShare = () => {
    const { FB } = window;
    if (FB) {
      try {
        FB.ui(
          {
            method: 'share',
            mobile_iframe: true,
            href: `${RestaurantLink}`,
          },
          () => {
            updateFacebookOffer({
              // mark as completed
              variables: { reference: BookingReference },
            }).then(() =>
              navigate(`/booking/${BookingReference}/facebookshare`),
            );
          },
        );
      } catch (e) {
        // there was an error, show a message
        alert.error(errorMessage(e));
        hideModalFunc();
      }
    } else {
      // if there was an error, mark the share clicked anyway
      updateFacebookOffer({
        variables: { reference: BookingReference, clicked: true },
      })
        .then(() => navigate(`/booking/${BookingReference}/facebookshare`))
        .catch();
    }
  };
  const freeCredit = freeCreditAmount(siteConfig, false);

  return (
    <PromotionModal
      shareData={shareData}
      title={`Earn ${freeCredit} free credit!`}
      titleBody="Want to dine out for less?"
      content={`Share your recent booking on Facebook and earn ${freeCredit} credit towards your next First Table booking fee`}
      shareButtonAction={fbShare}
      shareButtonText="&nbsp;&nbsp;Share your booking on Facebook"
      buttonIcon="l-facebook-2"
      hideModal={hideModalFunc}
    />
  );
};

ModalToDisplay.propTypes = {
  shareData: PropTypes.shape(),
  siteConfig: PropTypes.shape(),
};

const BookingConfirmation = ({ query, referenceId, siteConfig }) => {
  const { showModalFunc } = useContext(ModalContext);
  const { loading, error, data } = useQuery(query, {
    variables: { reference: referenceId },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    // if the user has share data included
    const bookingData = data?.booking || {};

    if (bookingData?.shareData) {
      const fbShareData = bookingData.shareData
        ? JSON.parse(bookingData.shareData)
        : null;

      if (fbShareData.Qualified) {
        showModalFunc(
          <ModalToDisplay shareData={fbShareData} siteConfig={siteConfig} />,
          {
            timeout: 3000,
            type: 'fbShare',
          },
        );
      }
    }
  }, [data, showModalFunc, siteConfig]);
  if (loading) {
    return <LoadingLayout showFooter showMenu />;
  }
  if (error) return <ErrorLayout />;

  const { booking } = data;
  const shareData = booking.shareData ? JSON.parse(booking.shareData) : null;
  const ogData = shareData ? shareData.ShareProperties.restaurant : {};
  return (
    <ThemeLayout footer={<BookingFooter NavLinkComponent={NavLink} />}>
      {ogData && (
        <SEO
          ogType={ogData['og:type']}
          title={ogData['og:title']}
          description={ogData['og:description']}
          ogImage={ogData['og:image']}
        />
      )}
      <MainMenu />
      <BookingConfirmationContent booking={booking} />
    </ThemeLayout>
  );
};
BookingConfirmation.defaultProps = {};

BookingConfirmation.propTypes = {
  query: PropTypes.object.isRequired,
  referenceId: PropTypes.string.isRequired,
  siteConfig: PropTypes.shape().isRequired,
};

export default BookingConfirmation;
