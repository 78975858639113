import { useState, useEffect } from 'react';

const useFacebookLoading = () => {
  const [loaded, setLoaded] = useState(false);

  const initializeFacebookSDK = () => {
    // eslint-disable-next-line func-names
    window.fbAsyncInit = function () {
      FB.init({ // eslint-disable-line
        appId: process.env.GATSBY_FACEBOOK_ID,
        cookie: true, // enable cookies to allow the server to access the session
        version: 'v6.0',
      });
      setLoaded(true);
    };

    // Load the SDK asynchronously
    // eslint-disable-next-line func-names
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  useEffect(() => {
    initializeFacebookSDK();
  }, []);

  return loaded;
};

export default useFacebookLoading;
