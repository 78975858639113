import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { graphql as apolloGraphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Box } from '@firsttable/web-components/atoms';
import {
  errorMessage,
  removeCartEvent,
  scrollTo,
} from '@firsttable/functions';
import Cookies from 'js-cookie';
import { CardElement } from '@stripe/react-stripe-js';
import { useMutation } from '@apollo/client';
import { BookingForm } from '@firsttable/web-components/organisms/Forms';
import { dataLayer, fbq } from '../../../helpers/events';
import parseHtml from '../../../helpers/parseHtml';

export const UPDATE_BOOKING = gql`
  mutation updateBooking(
    $bookingId: Int!
    $name: String
    $email: String
    $phone: String
    $people: Int
    $paymentMethod: String
    $stripeToken: String
    $saveCard: Boolean
    $dinedBefore: String
    $leaveReview: String
    $news: String
    $conditions: String
    $additionalPeople: Int
    $dietaryRequirements: String
    $contactPermission: Boolean
  ) {
    updatedBooking: updateBooking(
      bookingId: $bookingId
      name: $name
      email: $email
      phone: $phone
      people: $people
      paymentMethod: $paymentMethod
      stripeToken: $stripeToken
      saveCard: $saveCard
      dinedBefore: $dinedBefore
      leaveReview: $leaveReview
      news: $news
      conditions: $conditions
      additionalPeople: $additionalPeople
      dietaryRequirements: $dietaryRequirements
      contactPermission: $contactPermission
    ) {
      id
      status
      redirectURL
      member {
        upcomingBookings {
          edges {
            node {
              restaurant {
                id
              }
              availability {
                id
                session
              }
              date
              time
              status
            }
          }
        }
      }
    }
  }
`;

const SET_BOOKING_STALE = gql`
  mutation clearPendingBooking($reference: String) {
    changeProductStatus(reference: $reference, newStatus: "Changed Mind") {
      id
      status
    }
  }
`;

const StyledConditions = styled(Box)`
  color: ${themeGet('colors.grayscale.600')};
  ol {
    margin: 0;
    padding: 0 0 20px 25px;
  }
`;

// Should this user use the charge flow
const isCharge = (booking) => false;

const Form = ({
  values,
  handleSubmit,
  handleChange,
  handleBlur,
  isSubmitting,
  setFieldValue,
  booking,
  termsAndConditions,
  user,
  ...props
}) => {
  const [changeProductStatus] = useMutation(SET_BOOKING_STALE);
  const paymentOptions = [
    {
      sort: 3,
      inputLabel: 'Pay with new card',
      inputValue: isCharge(booking) ? 'NewCard' : 'NewPaymentIntention',
    },
  ];
  // payment express for AU & NZ only
  if ([1, 2].indexOf(+process.env.GATSBY_SITE_ID) !== -1) {
    paymentOptions.push({
      sort: 2,
      inputLabel:
        'Pay with bank account (also known as Account2Account or POLi)',
      inputValue: 'BankTransfer',
    });
  }

  if (user.last4) {
    paymentOptions.unshift({
      sort: 1,
      inputLabel: `Pay with saved card (ending in ${user.last4})`,
      inputValue: isCharge(booking) ? 'SavedCard' : 'PaymentIntention',
    });
  }

  useEffect(() => {
    dataLayer.push({
      google_tag_params: {
        local_id: booking.restaurant.id,
        local_dealid: booking.restaurant.id,
        local_pagetype: 'conversionintent',
        local_totalvalue: booking.price,
      },
    });

    fbq('track', 'AddToCart', {
      content_name: booking.restaurant.menuTitle,
      content_ids: [booking.restaurant.id],
      content_type: 'product',
    });

    return () => {
      removeCartEvent(booking);

      // change product status on umount to changed mind
      changeProductStatus({
        variables: { reference: booking.reference },
      })
        .then(() => {})
        .catch((e) => e);
    };
  }, []);

  // const lts = { 2: '22:30:00', 3: '22:30:00', 4: '22:30:00' };
  // const lt = '2020-03-21 19:30:00';
  let leaveTime = null;
  if (booking.availability.leaveTime) {
    leaveTime = booking.availability.leaveTime;
  }
  if (booking.availability.leaveTimes) {
    leaveTime = JSON.parse(booking.availability.leaveTimes);
  }
  return (
    <BookingForm
      user={user}
      handleBlur={handleBlur}
      booking={booking}
      setFieldValue={setFieldValue}
      paymentOptions={paymentOptions}
      handleChange={handleChange}
      values={values}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      leaveTime={leaveTime}
      specialConditions={
        <StyledConditions>
          {parseHtml(termsAndConditions.content)}
        </StyledConditions>
      }
      {...props}
    />
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  stripe: PropTypes.shape({
    createToken: PropTypes.func,
  }).isRequired,
  termsAndConditions: PropTypes.shape({
    content: PropTypes.string,
  }).isRequired,
  values: PropTypes.object,
  booking: PropTypes.shape().isRequired,
  user: PropTypes.shape(),
};

Form.defaultProps = {
  values: {},
  user: {},
  isSubmitting: false,
};

export default compose(
  apolloGraphql(UPDATE_BOOKING, { name: 'updateBooking' }),
  withFormik({
    displayName: 'BookingForm',
    mapPropsToValues: ({ user, siteConfig, booking }) => {
      // Use intent for Queenstown.
      let paymentType = user.last4 ? 'SavedCard' : 'NewCard';
      if (!isCharge(booking)) {
        paymentType = user.last4 ? 'PaymentIntention' : 'NewPaymentIntention';
      }

      return {
        name: `${user.firstName} ${user.surname}`,
        email: user.email,
        people: 2,
        phone: user.phoneNumber || '',
        dinedBefore: booking.dinedPreviously ? '1' : '0',
        contactPermission: user.contactPermission ? '1' : null,
        leaveReview: '1',
        additionalPeople: null,
        news: !siteConfig.GDPRCountry,
        conditions: false,
        payment: paymentType,
        saveCard: false,
        dietaryRequirements: '',
      };
    },
    validationSchema: () =>
      Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        phone: Yup.string().required('Phone number is required'),
        email: Yup.string().email('Invalid email').required('Required'),
        conditions: Yup.boolean()
          .oneOf([true], 'Must Accept Terms and Conditions')
          .required(),
        contactPermission: Yup.boolean()
          .nullable()
          .required('Please select an option'),
      }),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        props: { booking, updateBooking, stripe, elements },
      },
    ) => {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const formData = values;
      const cardElement = elements.getElement(CardElement);

      const bookingData = {
        ...formData,
        bookingId: booking.id,
        people: +formData.people,
        paymentMethod: formData.payment,
        news: formData.news ? '1' : '0',
        additionalPeople: +formData.additionalPeople,
        contactPermission: +values.contactPermission === 1,
      };
      if (Number(booking.amountToPay) === 0) {
        bookingData.paymentMethod = 'None';
      }
      if (bookingData.paymentMethod === 'NewPaymentIntention') {
        const { paymentMethod, error: tokenError } =
          await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: bookingData.name,
            },
          });

        if (tokenError) {
          setStatus({ message: errorMessage(tokenError), type: 'danger' });
          setSubmitting(false);
          return;
        }
        if (paymentMethod) {
          bookingData.stripeToken = `${paymentMethod.id}`;
        }
      }

      if (bookingData.paymentMethod === 'NewCard') {
        // do anything with bank transfer bookings
        const { token, error: tokenError } = await stripe.createToken(
          cardElement,
          { name: bookingData.name },
        );

        if (tokenError) {
          setStatus({ message: errorMessage(tokenError), type: 'danger' });
          setSubmitting(false);
          return;
        }
        if (token) {
          bookingData.stripeToken = `${token.id}`;
        }
      }

      // do the booking processing
      try {
        const { data: processedBooking } = await updateBooking({
          variables: {
            ...bookingData,
          },
        });
        if (processedBooking) {
          const { updatedBooking } = processedBooking;
          if (updatedBooking.member.upcomingBookings.edges.length > 0) {
            Cookies.set(
              'UpcomingBookings',
              JSON.stringify(updatedBooking.member.upcomingBookings.edges),
            );
          }
          if (bookingData.paymentMethod === 'BankTransfer') {
            if (updatedBooking.redirectURL && typeof window !== 'undefined') {
              window.location = updatedBooking.redirectURL;
            } else {
              setStatus({
                message:
                  'There was an error redirecting you to the payment gateway.',
                type: 'danger',
              });
              scrollTo('form-alert', -20);
              setSubmitting(false);
            }
            return;
          }
          if (updatedBooking.status === 'Confirmed') {
            await navigate(`/booking/${booking.reference}/confirmation`);
          } else {
            setStatus({
              message: 'There was an error processing your booking',
              type: 'danger',
            });
            scrollTo('form-alert', -20);
            setSubmitting(false);
          }
        }
      } catch (e) {
        setStatus({
          message: errorMessage(e, 'There was an error processing payment'),
          type: 'danger',
        });
        setSubmitting(false);
      }
    },
  }),
)(Form);
