import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Button,
  Flex,
  BodyTitle,
  Title,
} from '@firsttable/web-components/atoms';
import ModalContext from '../../../../context/ModalContext';

const Conditions = ({ terms }) => {
  const { hideModalFunc } = useContext(ModalContext);
  return (
    <Flex height="100vh">
      <Box m="auto">
        <Box
          position="relative"
          width="100%"
          maxWidth="508px"
          p={['40px 16px', '56px 64px']}
          bg="white"
        >
          <Title as="h4" size="h6">
            Special Conditions
          </Title>
          {terms && (
            <>
              <BodyTitle size="s">
                This restaurant has the following special conditions for First
                Table reservations:
              </BodyTitle>
              <Text dangerouslySetInnerHTML={{ __html: terms || null }} />
            </>
          )}
          {+process.env.GATSBY_SITE_ID === 4 && (
            <Box>
              <BodyTitle size="s">
                Tipping when dining with First Table
              </BodyTitle>
              <Text>
                We would recommend leaving a gratuity between 15% and 20% of the
                total bill (before discounts) when dining at our partner
                restaurants in Canada.
              </Text>
              <Text>
                Please remember that your servers will be working just as hard
                for your table (regardless of you receiving half-price food).
              </Text>
            </Box>
          )}
          <Text>
            You must accept these conditions to continue. These conditions will
            be noted on your reservation confirmation.
          </Text>
          <Box mt={['24px', '40px']} textAlign="center">
            <Button
              data-testid="conditions-btn"
              onClick={() => hideModalFunc()}
              size="l"
              fontWeight="600"
            >
              Accept conditions
            </Button>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

Conditions.propTypes = {
  terms: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default Conditions;
