import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Router } from '@reach/router';
import gql from 'graphql-tag';
import { graphql, navigate } from 'gatsby';
import { useMutation, useQuery } from '@apollo/client';
import { createBooking as CREATE_BOOKING } from '@firsttable/graphql-queries/src';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import {
  checkoutStepEvent,
  dayMonthYear,
  errorMessage,
  isBrowser,
} from '@firsttable/functions';
import { Button, Title, Text } from '@firsttable/web-components/atoms';
import { loadStripe } from '@stripe/stripe-js';
import MainMenu from '../components/organisms/Navigation/Menu';
import ThemeLayout from '../layouts/layout';
import BookingForm from '../components/organisms/Forms/BookingForm';
import BookingConfirmationLayout from '../layouts/BookingConfirmation';
import PrivateRoute from '../components/PrivateRoute';
import LoadingLayout from '../layouts/LoadingLayout';
import MissingRoute from '../layouts/MissingRoute';
import GenericLayout from '../layouts/GenericLayout';
import RestaurantConditions from '../components/organisms/Modal/Restaurant/Conditions';
import ErrorLayout from '../layouts/ErrorLayout';
import ModalContext from '../context/ModalContext';
import NavButton from '../components/atoms/NavButton';

const GET_BOOKING = gql`
  query getBooking($reference: String) {
    user: Member {
      id
      last4
      email
      firstName
      surname
      phoneNumber
      contactPermission
      accountBalances {
        edges {
          node {
            amount
            currency
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
    booking: Booking(reference: $reference) {
      id
      reference
      date
      time
      email
      people
      price
      priceInNZD
      amountToPay
      currency
      credit
      specialConditions
      status
      redirectURL
      hasReview
      reservationConfirmation
      sentToGoogleAnalytics
      restaurantMarketingPermission
      shareData
      dinedPreviously
      conversionData
      availability {
        id
        leaveTime
        leaveTimes
        allowedDiners
        session
      }
      restaurant {
        id
        title
        menuTitle
        additionalPeopleAllowed
        additionalPeopleForbidden
        askForDietaryRequirements
        integrationList
        bookingPrice
        priceInNZD
        region {
          id
          menuTitle
          slug
        }
      }
    }
  }
`;

const GET_REGION_SLUG = gql`
  query getRestaurantRegionSlug($id: Int!) {
    Restaurant(id: $id) {
      region {
        menuTitle
        slug
      }
    }
  }
`;

const REACTIVATE_BOOKING_STALE = gql`
  mutation reactivateBooking($reference: String) {
    changeProductStatus(reference: $reference, newStatus: "Pending") {
      id
      status
    }
  }
`;

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);

const StripeConnectedBookingForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  return <BookingForm elements={elements} stripe={stripe} {...props} />;
};
const BookingPage = ({ referenceId, ...props }) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    GET_BOOKING,
    {
      fetchPolicy: 'network-only',
      variables: { reference: referenceId },
      notifyOnNetworkStatusChange: true,
    },
  );
  const { showModalFunc } = useContext(ModalContext);
  const [changeProductStatus, { loading: loadingReactivation }] = useMutation(
    REACTIVATE_BOOKING_STALE,
  );
  const isRefetching = networkStatus === 4;
  // const {
  //   loading: loadingAccountBalances,
  //   data: accountBalanceData,
  // } = useQuery(GET_ACCOUNT_BALANCES, {
  //   fetchPolicy: 'network-only',
  // });

  useEffect(() => {
    const bookingConditions = data?.booking || {};
    // run change product status if booking is set to changed mind and we aren't already refetching
    if (bookingConditions.status === 'Changed Mind' && !isRefetching) {
      changeProductStatus({
        variables: { reference: bookingConditions.reference },
      }).then(({ data: reactivatedData }) => {
        // refetch the query if we were able to change product to pending
        if (reactivatedData.changeProductStatus.status === 'Pending') {
          refetch();
        }
      });
    }
    if (
      (bookingConditions.specialConditions ||
        +process.env.GATSBY_SITE_ID === 4) &&
      bookingConditions.status === 'Pending'
    ) {
      showModalFunc(
        <RestaurantConditions
          terms={bookingConditions.specialConditions || null}
        />,
        {
          timeout: 1000,
          disableCloseBtn: true,
          disableEscExit: true,
          type: 'restaurantConditions',
        },
      );
    }
  }, [changeProductStatus, data, isRefetching, refetch, showModalFunc]);

  useEffect(() => {
    const bookingEvent = data?.booking;
    if (bookingEvent) {
      checkoutStepEvent(bookingEvent);
    }
  }, [data]);

  // show loading screen if loading main query, reactivation query or refetching main query
  if (loading || loadingReactivation || isRefetching) {
    return <LoadingLayout showMenu showFooter />;
  }
  if (error) return <ErrorLayout />;
  const { booking, user } = data;

  if (booking.status === 'Confirmed') {
    navigate(`/booking/${booking.reference}/confirmation`);
  }

  const convData = JSON.parse(booking.conversionData);


  return (
    <ThemeLayout>
      <MainMenu onlyLogo />
      <ContainerContent>
        {['Pending', 'Paying'].includes(booking.status) ? (
          <>
            <RowHead title={`Reservation at ${booking.restaurant.title}`} />
            <StripeConnectedBookingForm
              booking={booking}
              user={{ ...user }}
              {...props}
            />
          </>
        ) : (
          <>
            <Title>Sorry, that table is no longer available</Title>
            <Text>
              Your booking was not confirmed because that First Table is no
              longer available.
            </Text>
              {/*{!loading && user.accountBalances.edges.length > 0 && (*/}
              {/*  <>*/}
              {/*    <Text>*/}
              {/*      Although your booking wasn't confirmed, your payment was*/}
              {/*      successful. Your account balance is as follows.*/}
              {/*    </Text>*/}
              {/*    {user.accountBalances.edges.map(({ node }) => (*/}
              {/*      <Text key={node.currency}>*/}
              {/*        Currency: {node.currency}{' '}*/}
              {/*        {currencyFormat(node.amount, node.currency)}.*/}
              {/*      </Text>*/}
              {/*    ))}*/}
              {/*  </>*/}
              {/*)}*/}
            {/* <Title>Sorry, that table is no longer available</Title> */}
            {/* <Text> */}
            {/*  It looks like you&rsquo;ve just missed out on this table. Luckily */}
            {/*  we have no shortage of amazing partner restaurants for you to dine */}
            {/*  at so follow the prompt below to find another table. */}
            {/* </Text> */}
            {booking.restaurant.region && (
              <Button
                kind="cta"
                to={booking.restaurant.region.slug}
                NavComponent={NavButton}
              >
                {`Book a table in ${booking.restaurant.region.menuTitle}`}
              </Button>
            )}
          </>
        )}
      </ContainerContent>
    </ThemeLayout>
  );
};

BookingPage.defaultProps = {};

BookingPage.propTypes = {
  referenceId: PropTypes.string,
};

const ElementsBookingPage = (props) => (
  <Elements stripe={isBrowser ? stripePromise : null}>
    <BookingPage {...props} />
  </Elements>
);

const CREDIT_SHARE_QUERY = gql`
  query shareCredit($reference: String!) {
    Credit(reference: $reference) {
      title
      currency
      amount
      expires
    }
  }
`;
// todo mutation in backend for credit - facebookOfferSuccess on BookingController
const FacebookShareSuccess = () => {
  const { data, loading, error } = useQuery(CREDIT_SHARE_QUERY, {
    variables: { reference: 'FacebookShare' },
    fetchPolicy: 'network-only',
  });
  if (loading || error) {
    return <LoadingLayout showFooter showMenu />;
  }
  return (
    <GenericLayout>
      <ContainerContent>
        <Title>You&rsquo;ve earned free credit!</Title>
        <Text>
          Thanks for sharing! You&rsquo;re helping us grow First Table.
        </Text>
        <Text>
          Your credit is valid until {dayMonthYear(data.Credit.expires)} and
          it&rsquo;ll go towards your next First Table booking.
        </Text>
      </ContainerContent>
    </GenericLayout>
  );
};

const ProcessBooking = ({ availabilityId, restaurantId }) => {
  const [createBooking, { loading }] = useMutation(CREATE_BOOKING, {
    variables: { availabilityId },
  });
  const [error, setError] = useState(null);
  useEffect(() => {
    setError(null);
    createBooking()
      .then(async (d) => {
        const {
          data: {
            createBooking: { reference },
          },
        } = d;
        await navigate(`/booking/${reference}/details`, { replace: true });
      })
      .catch((e) => setError(errorMessage(e)));
  }, []);
  const {
    loading: regionLoading,
    data,
    error: regionError,
  } = useQuery(GET_REGION_SLUG, {
    variables: { id: restaurantId },
  });
  if (loading || regionLoading || regionError) {
    return <LoadingLayout showFooter showMenu />;
  }
  return (
    <GenericLayout>
      {error && (
        <ContainerContent>
          <Title>Sorry, that table is no longer available</Title>
          <Text>
            It looks like you&rsquo;ve just missed out on this table. Luckily we
            have no shortage of amazing partner restaurants for you to dine at
            so follow the prompt below to find another table.
          </Text>
          {data.Restaurant.region && (
            <Button
              NavComponent={NavButton}
              kind="cta"
              to={data.Restaurant.region.slug}
            >{`Book a table in ${data.Restaurant.region.menuTitle}`}</Button>
          )}
        </ContainerContent>
      )}
    </GenericLayout>
  );
};

ProcessBooking.propTypes = {
  availabilityId: PropTypes.string.isRequired,
  restaurantId: PropTypes.string,
};

const BookingPageRouting = ({ data: { siteConfig, termsAndConditions } }) => (
  <Router>
    <PrivateRoute
      path="/booking/:referenceId/details"
      component={ElementsBookingPage}
      siteConfig={siteConfig}
      termsAndConditions={termsAndConditions}
    />
    <PrivateRoute
      path="/booking/:referenceId/confirmation"
      component={BookingConfirmationLayout}
      siteConfig={siteConfig}
      query={GET_BOOKING}
    />
    <PrivateRoute
      path="/booking/:referenceId/facebookshare"
      component={FacebookShareSuccess}
    />
    <PrivateRoute
      path="/booking/:availabilityId/:restaurantId/process"
      component={ProcessBooking}
    />
    <MissingRoute default />
  </Router>
);

BookingPageRouting.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default BookingPageRouting;

export const query = graphql`
  query getBookingFormData {
    siteConfig {
      GDPRCountry
      currency
      freeCreditAmount
    }
    termsAndConditions: setting(key: { eq: "TermsAndConditions" }) {
      id
      key
      content
      title
    }
  }
`;
